import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "before", "toggle", "cancel", "after"]
  static values = { id: Number, index: Number }

  connect() {
    "positionable"
  }

  start(e) {
    console.log(e.currentTarget.dataset)
    this.idValue = e.currentTarget.dataset.positionableId;
    this.indexValue = this.toggleTargets.indexOf(e.currentTarget)
  }

  cancel(e) {
    this.idValue = -1;
  }

  toggle(e) {
    if (this.idValue > 0) {
      this.cancel(e);
    } else {
      this.start(e);
    }
  }

  before(e) {
    window.location.href = `${e.currentTarget.dataset.path}?id=${this.idValue}&other=${e.currentTarget.dataset.positionableId}&direction=before`
  }

  after(e) {
    window.location.href = `${e.currentTarget.dataset.path}?id=${this.idValue}&other=${e.currentTarget.dataset.positionableId}&direction=after`
  }

  idValueChanged() {
    if (this.idValue > 0) {
      this.beforeTargets.forEach((el, i) => {
        if (this.indexValue != i && this.indexValue != i - 1) {
          el.classList.add('visible');
        }
      })
      this.toggleTargets.forEach((el, i) => {
        if (this.indexValue == i) {
          el.classList.add('unimportant');
        } else {
          el.classList.add('hidden');
        }
      })
      if (this.indexValue != this.afterTargets.length - 1) {
        this.afterTargets[this.afterTargets.length - 1].classList.add('visible');
      }
    } else {
      this.beforeTargets.forEach((el, i) => {
        el.classList.remove('visible');
      })
      this.toggleTargets.forEach((el, i) => {
        el.classList.remove('unimportant');
        el.classList.remove('hidden');
      })
      this.afterTargets.forEach((el, i) => {
        el.classList.remove('visible');
      })
    }
  }

}
import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "factor", "result", "button" ]
  static values = { factor: Number }

  change(e) {
    this.factorValue = this.factorTarget.value

    e.preventDefault()
    e.stopPropagation()
  }

  factorValueChanged() {
    console.log(this.factorValue)
    let factor = this.factorValue
    let result = 0
    let button_caption = 'Anfrage stellen'

    if (factor == 0) {
      result = `${1 * 449} €`
    } else if (factor > 0 && factor <= 5) {
      result = `${factor * 449} €`
      button_caption = 'Jetzt buchen'
    } else if (factor > 5 && factor <= 10) {
      result = `${factor * 449} €`
    } else if (factor > 10 && factor <= 20) {
      result = "Auf Anfrage"
    } else if (factor > 20) {
      result = "Auf Anfrage"
    } else {
      result = "-"
    }

    this.resultTarget.innerText = result
    this.buttonTarget.innerText = button_caption
  }


}
